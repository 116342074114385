import React from "react";
import logo from "./logo.svg";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import Home from "./pages/all_allowed/HomePage/home";
import SignInComponent from "./pages/all_allowed/SignInInstructions/SignIn";
import PrivateRoutes from "./routes/private_routes/PrivateRoutes";
import Header from "./components/common/Header/header";
import Head from "./components/common/Head/head";
import Footer from "./components/common/Footer/footer";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import Pricing from "./pages/all_allowed/Pricing/Pricing";
import AffiliateProgram from "./pages/user_only/affiliate/AffiliateProgram";
import AffiliateForm from "./pages/user_only/affiliate/AffiliateForm";
import AffiliateSuccess from "./pages/user_only/affiliate/AffiliateSuccess";
import SendSmsUsingGSheets from "./pages/all_allowed/guides/SendSmsUsingGSheets";
import SendSmsUsingAirtable from "./pages/all_allowed/guides/SendSmsUsingAirtable";
import SendEmailsUsingAirtable from "./pages/all_allowed/guides/SendEmailsUsingAirtable";
import SendEmailsUsingGSheets from "./pages/all_allowed/guides/SendEmailsUsingGSheets";
import Guides from "./pages/all_allowed/guides";
import { useState, useEffect } from "react";
import PrivacyPolicy from "./pages/all_allowed/guides/PrivacyPolicy";
import TermsOfService from "./pages/all_allowed/guides/TermsOfService";


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [loading, setLoading] = useState(true);
  const [id, setId] = useState(null);
  const [uid, setUid] = useState(null);

  const verifyToken = async () => {

    const url = 'https://postsheet.com/api/check-auth';
    try {
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          
        },
      });

      if (!response.ok) {
        const errorData = await response.json(); // Tente ler a resposta JSON
        throw new Error(`Falha na autenticação: ${errorData.detail || 'Erro desconhecido'}`);
      }

      const data = await response.json();

      setIsAuthenticated(data.isAuthenticated);
      setId(data.encrypted_id); // Atualiza o id com o valor descriptografado recebido
      setUid(data.encrypted_uid);

    } catch (error) {
      console.error('Erro ao verificar a autenticação:', error.message);
      setIsAuthenticated(false);
      
    }
  };


 

  useEffect(() => {
    verifyToken().finally(() => setLoading(false));
  }, []);
  return (
    
    <>
    <ThemeProvider theme={theme}>
    <Header isAuthenticated={isAuthenticated} loading={loading}/>
    <Head/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/signin" element={<SignInComponent />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route
                    path="/affiliate-program"
                    element={
                        <AffiliateProgram />
                    }
                  />
                   <Route
                    path="/affiliate/signup"
                    element={
                        <AffiliateForm />
                    }
                  />
                  <Route
                    path="/affiliate/success"
                    element={
                        <AffiliateSuccess />
                    }
                  />
                     <Route
                    path="/guides/sendsmsgsheets"
                    element={
                        <SendSmsUsingGSheets />
                    }
                  />
                  <Route
                    path="/guides/sendsmsairtable"
                    element={
                        <SendSmsUsingAirtable />
                    }
                  />

<Route
                    path="/guides/sendemailsairtable"
                    element={
                        <SendEmailsUsingAirtable />
                    }
                  />
                  <Route
                    path="/guides/sendemailsgoogle"
                    element={
                        <SendEmailsUsingGSheets />
                    }
                  />
                  <Route
                    path="/guides"
                    element={
                        <Guides />
                    }
                  />
        </Routes>
        
        <Routes>
          <Route path="/app/*" element={<PrivateRoutes />} />
       
        </Routes>
      <Footer/>
      </ThemeProvider>
        </>
      
  );
}

export default App;