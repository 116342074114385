import React, { useEffect } from "react";
import { Routes, Route, Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import SidebarComponent from "../../components/common/Sidebar/Sidebar";
import Container from "@mui/material/Container";
import { columns_test } from "../../pages/user_only/new_job/NewDataSource/test_data";
import {
  AppContainer,
  Confined,
  DashboardContainer,
  Flex,
} from "../../styled-components/Containers";
import NewCampaign from "../../pages/user_only/new_job/NewDataSource";
import { AuthProvider } from "../../contexts/AuthContext";
import Grid from "@mui/material/Grid";
import UserJobs from "../../pages/user_only/jobs/Jobs";
import { JobsProvider } from "../../contexts/JobsContext";
import { AccountsProvider } from "../../contexts/AccountsContext";
import Accounts from "../../pages/user_only/accounts/Accounts";
import { UserFilesProvider } from "../../contexts/UserFilesContext";
import UserFilesTable from "../../pages/user_only/user_files/userFilesTable";
import { ProfileProvider } from "../../contexts/ProfileContext";
import Profile from "../../pages/user_only/profile/Profile";
import AddPlanManagedAccount from "../../pages/user_only/profile/AddPlanManagedAccount";
import { NewCampaignProvider } from "../../contexts/NewCampaignContext";
import AddAccount from "../../pages/user_only/accounts/AddAccount/AddAccount";
import AddAccountAirtable from "../../pages/user_only/accounts/AddAccount/AddAccountAirtable";
import TextEditor from "../../pages/user_only/new_job/ActionConfig/Editor/Editor";
import ActionConfig from "../../pages/user_only/new_job/ActionConfig/ActionConfig";
import PreviewPanel from "../../pages/user_only/new_job/Preview/Preview";
import { EditorProvider } from "../../contexts/EditorContext";
import JobDetails from "../../pages/user_only/jobs/details/tabs/JobDetails/JobDetails";
import { JobDetailsProvider } from "../../contexts/JobDetailsContext";
import JobDetailsMain from "../../pages/user_only/jobs/details";
import { ThreadsProvider } from "../../contexts/ThreadsContext";
import { ReplyEditorProvider } from "../../contexts/ReplyEditorContext";
import TemplateTable from "../../pages/user_only/templates/TemplatesTable";
import TemplateDetailsMain from "../../pages/user_only/templates/TemplateDetails";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import StartEarning from "../../pages/user_only/affiliate/StartEarning";
import AffiliateProgram from "../../pages/user_only/affiliate/AffiliateProgram";
import Contact from "../../pages/user_only/contact/contact";
import AffiliateRoute from "../../pages/user_only/affiliate/AffiliateRoute";
import CreateTemplate from "../../pages/user_only/templates/CreateTemplate";
import CreateTemplateSms from "../../pages/user_only/templates/CreateTemplateSms";
import ReplyAllComponent from "../../pages/user_only/jobs/details/tabs/Threads/ReplyAllComponent";
import ErrorComponent from "../../pages/error/ErrorComponent";

const PrivateRoute = () => {
  const { isAuthenticated, loading, verifyToken } = useAuth();
  let location = useLocation();

  useEffect(() => {

    verifyToken();
  }, [verifyToken]);

  if (loading) {
    return (
      <Paper sx={{ width: "100%", overflow: "hidden", padding: '1rem' }}>
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      </Paper>
    );
  }

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/signin" replace state={{ from: location }} />
  );
};

const PrivateRoutes = () => {
  return (
    <AuthProvider>
      <AppContainer>
        <DashboardContainer>
          <Container maxWidth="lg" style={{ marginTop: "20px" }}>
            <Flex>
              <SidebarComponent />

              <Routes>
                <Route element={<PrivateRoute />}>
                  <Route
                    path="/jobs/new"
                    element={
                      <NewCampaignProvider>
                        <EditorProvider>
                          <AccountsProvider>
                            <NewCampaign />
                          </AccountsProvider>
                        </EditorProvider>
                      </NewCampaignProvider>
                    }
                  />
                  <Route
                    path="/jobs"
                    element={
                      <JobsProvider>
                        <UserJobs />
                      </JobsProvider>
                    }
                  />
                  <Route
                    path="/affiliate"
                    element={

                        <AffiliateRoute />
                    }
                  />
                  <Route
                    path="/error"
                    element={

                        <ErrorComponent />
                    }
                  />
                  <Route
                    path="/templates/create"
                    element={
                      <NewCampaignProvider>
                        <CreateTemplate />
                        </NewCampaignProvider>
                    }
                  />
                  <Route
                    path="/templates/create/sms"
                    element={
                      <NewCampaignProvider>
                        <CreateTemplateSms />
                        </NewCampaignProvider>
                    }
                  />
                    {/* <Route
                    path="/threads/reply-all"
                    element={
                      <NewCampaignProvider>
                      <JobDetailsProvider>
                        <ThreadsProvider>
                          <ReplyEditorProvider>
                            <ReplyAllComponent />
                          </ReplyEditorProvider>
                        </ThreadsProvider>
                      </JobDetailsProvider>
                    </NewCampaignProvider>
                    }
                  /> */}
                    <Route
                    path="/support"
                    element={

                        <Contact />
                    }
                  />
                  <Route
                    path="/template/:uid"
                    element={
                      <NewCampaignProvider>
                        <TemplateDetailsMain />
                      </NewCampaignProvider>
                    }
                  />
                  <Route
                    path="/templates"
                    element={
                      <TemplateTable title="Templates List" noDataText="No templates available" />
                    }
                  />
                  <Route
                    path="/accounts"
                    element={
                      <AccountsProvider>
                        <Accounts />
                      </AccountsProvider>
                    }
                  />
                  <Route
                    path="/user-files"
                    element={
                      <NewCampaignProvider>
                      <UserFilesProvider>
                        <UserFilesTable />
                      </UserFilesProvider>
                      </NewCampaignProvider>
                    }
                  />
                  <Route
                    path="/accounts/add/:accountKey"
                    element={
                      <NewCampaignProvider>
                        <AccountsProvider>
                        <AddAccount />
                        </AccountsProvider>
                      </NewCampaignProvider>
                    }
                  />
                  {/* <Route
                    path="/accounts/addaccount/airtable"
                    element={
                      <NewCampaignProvider>
                        <AddAccountAirtable />
                      </NewCampaignProvider>
                    }
                  /> */}
                  <Route
                    path="/profile"
                    element={
                      <ProfileProvider>
                        <Profile />
                      </ProfileProvider>
                    }
                  />
                  <Route
                    path="/details/:uid"
                    element={
                      <NewCampaignProvider>
                        <JobDetailsProvider>
                          <ThreadsProvider>
                            <ReplyEditorProvider>
                              <JobDetailsMain />
                            </ReplyEditorProvider>
                          </ThreadsProvider>
                        </JobDetailsProvider>
                      </NewCampaignProvider>
                    }
                  />
                  <Route
                    path="/profile/managed-accounts/add-payment"
                    element={
                      <ProfileProvider>
                        <AddPlanManagedAccount />
                      </ProfileProvider>
                    }
                  />
                </Route>
              </Routes>
            </Flex>
          </Container>
        </DashboardContainer>
      </AppContainer>
    </AuthProvider>
  );
};

export default PrivateRoutes;
