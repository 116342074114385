import React from "react";
import {
  Paper,
  Box,
  Typography,
  TextField,
} from "@mui/material";
import { RiMailSendLine } from "react-icons/ri";
import { PrimaryButton } from "../../../../styled-components/Buttons";
import Options from "./Options";
import EmailPreview from "./EmailPreview";
import { useNewCampaign } from "../../../../contexts/NewCampaignContext";

function PreviewPanel({ emails, row_count }) {
  const { setJobName, sendJobData } = useNewCampaign();

  const handleCampaignNameChange = (event) => {
    setJobName(event.target.value);
  };

  return (
    <Paper elevation={3} sx={{ padding: 2, margin: 2, width: "100%" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box flexBasis="50%" sx={{ paddingRight: 2 }}>
          <Typography variant="h2" component="h2">
            New Campaign
          </Typography>
          <Typography variant="subtitle1" component="div">
            Everything looks good?
          </Typography>
        </Box>
        
        <Box
          flexBasis="50%"
          sx={{
            paddingLeft: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <TextField
            label="Campaign Name (optional)"
            variant="outlined"
            size="small"
            fullWidth
            onChange={handleCampaignNameChange}
          />
        </Box>
      </Box>
      <Options />
      <EmailPreview emails={emails} />
      <Box display="flex" justifyContent="flex-end">
        <PrimaryButton
          variant="contained"
          startIcon={<RiMailSendLine />}
          sx={{ marginTop: 1 }}
          onClick={sendJobData} // Attach the handler here
        >
          Send to {row_count} recipients
        </PrimaryButton>
      </Box>
    </Paper>
  );
}

export default PreviewPanel;
