import React, { createContext, useContext, useState, useEffect } from 'react';

// Atualização do contexto de autenticação para incluir id e uid
const AuthContext = createContext({
  isAuthenticated: false,
  setIsAuthenticated: (value) => {},
  id: null, // Adicionado
  setId: (value) => {},
  uid: null, // Adicionado
  setUid: (value) => {},
  setLoading: (value) => {},
  loading: true,
  verifyToken: () => Promise.resolve(), 
});

export const AuthProvider = ({ children }) => {
  
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState(null); // Estado para o id
  const [uid, setUid] = useState(null); // Estado para o uid


  const verifyToken = async () => {

    const url = 'https://postsheet.com/api/check-auth';
    try {
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        const errorData = await response.json(); // Tente ler a resposta JSON
        throw new Error(`Falha na autenticação: ${errorData.detail || 'Erro desconhecido'}`);
      }

      const data = await response.json();


      setIsAuthenticated(data.isAuthenticated);
      setId(data.encrypted_id); // Atualiza o id com o valor descriptografado recebido
      setUid(data.encrypted_uid);

    } catch (error) {
      console.error('Erro ao verificar a autenticação:', error.message);
      setIsAuthenticated(false);
    }
  };


 

  useEffect(() => {
    verifyToken().finally(() => setLoading(false));
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, id, uid, loading, verifyToken }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook personalizado para usar o contexto de autenticação
export const useAuth = () => useContext(AuthContext);
